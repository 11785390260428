import { isEmpty } from "utils/helpers";
import { getBatchListData, getChapterList, getCollegeListData, getCourseListDependentData, getProgramListData, getSubBatchListData, getSubTopicList, getSubjectChapterList } from "./metaDataApiFetch";

export const getCollegeFromStateId = async (stateId, updateMetaData) => {
  if (stateId?.length > 0) {
    try {
      const params = {};
      let stateProps = "";
      if (typeof stateId == "string") {
        params["state_id"] = stateId;
      } else if (stateId?.length > 0) {
        stateId.forEach((sateid,i) => {
                if(i==stateId.length-1){
                    stateProps = stateProps + sateid 
                }else{
                    stateProps = stateProps + sateid + ",";
                }
        });
        params["state_id"] = stateProps;

      }
      const call1: any = await getCollegeListData(updateMetaData, params);
      updateMetaData(call1[0], call1[1]);
    } catch (error) {
      console.log(error);
    }
  } else if (isEmpty(stateId)) {
    updateMetaData([], "college_list");
  }
};


export const getBatchFromClassId = async (classId, updateMetaData) => {
  if (classId?.length > 0) {
    try {
      const params = {};
      let classProps = "";
      if (typeof classId == "string") {
        params["class_id"] = classId;
      } else if (classId?.length > 0) {
        classId.forEach((classid,i) => {
            if(i==classId.length-1){
                classProps = classProps + classid 
            }else{
                classProps = classProps + classid + ",";
            }
        });
        params["class_id"] = classProps;
      }
      const call1: any = await getBatchListData(updateMetaData, params);
      updateMetaData(call1[0], call1[1]);
    } catch (error) {
      console.log(error);
    }
  } else if (classId == "") {
    updateMetaData([], "batch_list");
  }
};

export const getCourseFromClassId = async (classId, updateMetaData) => {
  if (classId?.length > 0) {
    try {
      const params = {};
      let classProps = "";
      if (typeof classId == "string") {
        params["class_id"] = classId;
      } else if (classId?.length > 0) {
        classId.forEach((classid,i) => {
            if(i==classId.length-1){
                classProps = classProps + classid 
            }else{
                classProps = classProps + classid + ",";
            }
        });
        params["class_id"] = classProps;
      }
      const call1: any = await getCourseListDependentData(updateMetaData, params);
      updateMetaData(call1[0], call1[1]);
    } catch (error) {
      console.log(error);
    }
  } else if ( isEmpty(classId)) {
    updateMetaData([], "course_list_dep");
  }
};

export const getSubBatchFromBatchId = async(batchId, updateMetaData) => {
  if (batchId?.length > 0) {
    try {
      const params = {};
      let batchProps = "";
      if (typeof batchId == "string") {
        params["batch_id"] = batchId;
      } else if (batchId?.length > 0) {
        batchId.forEach((batchid,i) => {
            if(i==batchId.length-1){
                batchProps = batchProps + batchid
            }else{
                batchProps = batchProps + batchid + ",";
            }
        });
        params["batch_id"] = batchProps;
      }
      const call1: any = await getSubBatchListData(updateMetaData, params);
      updateMetaData(call1[0], call1[1]);
    } catch (error) {
      console.log(error);
    }
  } else if (batchId == "") {
    updateMetaData([], "sub_batch_list");
  }
};

//---------------------- monthSchdule -----------------------------

export const getCourseFromClassIdForMs = async (classId, updateMetaData) => {
    if (classId?.length > 0) {
      try {
        const params = {};
        let classProps = "";
        if (typeof classId == "string") {
          params["class_id"] = classId;
        } else if (classId?.length > 0) {
          classId.forEach((classid,i) => {
              if(i==classId.length-1){
                  classProps = classProps + classid 
              }else{
                  classProps = classProps + classid + ",";
              }
          });
          params["class_id"] = classProps;
        }
        const call1: any = await getCourseListDependentData(updateMetaData, params);
        if(call1){
          return call1
        }else{
          return [[],"course_list_dep"]
        }
      } catch (error) {
        console.log(error);
      }
    } else if (classId == "" || classId?.length==0) {
      let arr=[[],"course_list_dep"]
      return arr
    }
  };

  export const getBatchFromClassIdForMs = async (classId, updateMetaData) => {
    const emptyRes=[[],"batch_list"]
    if (classId?.length > 0) {
      try {
        const params = {};
        let classProps = "";
        if (typeof classId == "string") {
          params["class_id"] = classId;
        } else if (classId?.length > 0) {
          classId.forEach((classid,i) => {
              if(i==classId.length-1){
                  classProps = classProps + classid 
              }else{
                  classProps = classProps + classid + ",";
              }
          });
          params["class_id"] = classProps;
        }
        const call1: any = await getBatchListData(updateMetaData, params);
        if(call1){
          return call1
        }else{
          return emptyRes
        }
      } catch (error) {
        console.log(error);
      }
    } else if (classId == "" || classId?.length==0) {
      return emptyRes
    }
  };

  export const getSubBatchFromBatchIdForUp = async(batchId, updateMetaData) => {
    if (batchId?.length > 0) {
      try {
        const params = {};
        let batchProps = "";
        if (typeof batchId == "string") {
          params["batch_id"] = batchId;
        } else if (batchId?.length > 0) {
          batchId.forEach((batchid,i) => {
              if(i==batchId.length-1){
                  batchProps = batchProps + batchid
              }else{
                  batchProps = batchProps + batchid + ",";
              }
          });
          params["batch_id"] = batchProps;
        }
        const call1: any = await getSubBatchListData(updateMetaData, params);
        if(call1){
          return call1
        }else{
          return [[],"sub_batch_list"]
        }
        
      } catch (error) {
        console.log(error);
      }
    } else if (isEmpty(batchId)) {
      let arr=[[],"sub_batch_list"]
      return arr
    }
  };

  export const getSubChapterFromhyrarchyForUp = async(hierarchy_type,) => {
    if (hierarchy_type?.length > 0) {
      try {
        const params = {};       
        if (hierarchy_type?.length > 0) {
          params["hierarchy_type"] = hierarchy_type;
        }
        const call1: any = await getSubjectChapterList( params);
        if(call1){
          return call1
        }else{
          return [[],"subject_chapter_list"]
        }
        
      } catch (error) {
        console.log(error);
      }
    } else if (hierarchy_type == "" || hierarchy_type?.length==0 || hierarchy_type==undefined) {
      let arr=[[],"subject_chapter_list"]
      return arr
    }
  };

  export const getCollegeFromStateIdForUp = async (stateId, updateMetaData) => {
    if (stateId?.length > 0) {
      try {
        const params = {};
        let stateProps = "";
        if (typeof stateId == "string") {
          params["state_id"] = stateId;
        } else if (stateId?.length > 0) {
          stateId.forEach((sateid,i) => {
                  if(i==stateId.length-1){
                      stateProps = stateProps + sateid 
                  }else{
                      stateProps = stateProps + sateid + ",";
                  }
          });
          params["state_id"] = stateProps;
        }
        const call1: any = await getCollegeListData(updateMetaData, params);
        if(call1){
          return call1
        }else{
          return [[],"college_list"]
        }
        
      } catch (error) {
        console.log(error);
      }
    } else if (stateId == ""|| stateId?.length==0 || stateId==undefined) {
      let arr=[[],"college_list"]
      return arr
    }
  };

  export const getChapterListFromSubjectBatch = async (subjectId) => {
    if (subjectId?.length > 0) {
      try {
        const params = {};       
          params["batch_id"] = process.env.REACT_APP_GENERIC_BATCH_ID;
          params["subject_id"] = subjectId;
    
        const call1: any = await getChapterList(params);

        if(call1){
          return call1
        }else{
          return [[],"chapter_list"]
        }
        
      } catch (error) {
        console.log(error);
      }
    } else if (subjectId == ""|| subjectId?.length==0 || subjectId==undefined) {
      let arr=[[],"chapter_list"]
      return arr
    }
  };

  export const getTopicListFromSubjectBatchChapter = async (subjectId,chapterId) => {
    if (subjectId?.length > 0 && chapterId.length > 0) {
      try {
        const params = {};       
          params["batch_id"] = process.env.REACT_APP_GENERIC_BATCH_ID;
          params["subject_id"] = subjectId;
          params["chapter_id"] = chapterId;
    
        const call1: any = await getSubTopicList(params);
        if(call1){
          return call1
        }else{
          return [[],"topic_list"]
        }
        
      } catch (error) {
        console.log(error);
      }
    } else if (chapterId == ""|| chapterId?.length==0 || chapterId==undefined) {
      let arr=[[],"topic_list"]
      return arr
    }
  };

  export const getProgramFromClassId = async (classId, updateMetaData) => {
    if (classId?.length > 0) {
      try {
        const params = {};
        let classProps = "";
        if (typeof classId == "string") {
          params["class_id"] = classId;
        } else if (classId?.length > 0) {
          classId.forEach((clsid,i) => {
                  if(i==classId.length-1){
                      classProps = classProps + clsid 
                  }else{
                      classProps = classProps + clsid + ",";
                  }
          });
          params["class_id"] = classProps;

        }
        const call1: any = await getProgramListData(updateMetaData, params);
        updateMetaData(call1[0], call1[1]);
      } catch (error) {
        console.log(error);
      }
    } else if (isEmpty(classId)) {
      updateMetaData([], "program_class_data");
    }
  };

  export const getCourseFromClassIdReturn = async (classId, updateMetaData) => {
    if (classId?.length > 0) {
      try {
        const params = {};
        let classProps = "";
        if (typeof classId == "string") {
          params["class_id"] = classId;
        } else if (classId?.length > 0) {
          classId.forEach((classid,i) => {
              if(i==classId.length-1){
                  classProps = classProps + classid 
              }else{
                  classProps = classProps + classid + ",";
              }
          });
          params["class_id"] = classProps;
        }
        const call1: any = await getCourseListDependentData(updateMetaData, params);
        
        if(call1){
          return call1
        }else{
          return [[],"course_list_dep"]
        }
      } catch (error) {
        console.log(error);
      }
    } else if ( isEmpty(classId)) {
      return [[],"course_list_dep"]
    }
  };

  export const getBatchFromClassIdReturn = async (classId, updateMetaData) => {
    if (classId?.length > 0) {
      try {
        const params = {};
        let classProps = "";
        if (typeof classId == "string") {
          params["class_id"] = classId;
        } else if (classId?.length > 0) {
          classId.forEach((classid,i) => {
              if(i==classId.length-1){
                  classProps = classProps + classid 
              }else{
                  classProps = classProps + classid + ",";
              }
          });
          params["class_id"] = classProps;
        }
        const call1: any = await getBatchListData(updateMetaData, params);
        if(call1){
          return call1
        }else{
          return [[],"batch_list"]
        }
      } catch (error) {
        console.log(error);
      }
    } else if (isEmpty(classId)) {
  
      return [[],"batch_list"]
    }
  };
