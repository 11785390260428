import LoginPage from "pages/login/Loadable";
import AssignmentsPage from "pages/assignments/Loadable";
import TermExamsPage from "pages/term-exams/Loadable";
import AnnouncementsPage from "pages/announcements/Loadable";
import LibraryPage from "pages/library/Loadable";
import MonthlySchedulePage from "pages/monthly-schedule/Loadable";
import LearnPage from "pages/learn/Loadable";
import ChangePaswordPage from "pages/change-password/Loadable";
import BuildTestPage from "pages/build-test/Loadable";
import QuestionMap from "pages/build-test/routes/test/question-map/Loadable";
import MiscellaneousPage from "pages/miscellaneous/Loadable";
import Error404 from "shared/templates/error/404";
import Error500 from "shared/templates/error/500";
import ErrorMainten from "shared/templates/error/under-mainten";
import PdfViewerComp from "shared/molecules/pdf-viewer";
import WarmUpTestPage from "pages/warm-up-delivery/Loadable";
import ActivitiesPage from "pages/activities/Loadable";
import HierarchiesPage from "pages/hierarchies/Loadable";
import CreateQuestionWithEditor from "pages/build-test/routes/question-grouping/create-question-with-editor/Loadable";
import { featureConfigConstants } from "app-component/helpers";
import ErrorPage from "shared/templates/error/403";
import GamesPage from "pages/games/Loadable";
import { ConfiguePage } from "pages/configue";
import { NCMS_ROLES } from "utils/role-restriction/helper";
export const publicRoutes = [
  {
    isPublic: true,
    exact: true,
    path: "/",
    component: LoginPage,
  },
  {
    isPublic: true,
    exact: true,
    path: "/login",
    component: LoginPage,
  },
  {
    isPublic: true,
    exact: true,
    path: "/403",
    component: ErrorPage,
  },
  {
    isPublic: true,
    exact: false,
    path: "/pdf-viewer/:id",
    component: PdfViewerComp,
  },
  {
    featureConfigKey: featureConfigConstants.DISABLE_BUILD_TEST,
    isPublic: false,
    exact: false,
    path: "/question-map",
    component: QuestionMap,
  },
  {
    featureConfigKey: featureConfigConstants.DISABLE_BUILD_TEST,
    isPublic: false,
    exact: true,
    path: "/question/:sourceType",
    component: CreateQuestionWithEditor,
  },
];

export const protectedRoutes = [
  {
    featureConfigKey: featureConfigConstants.DISABLE_ASSIGNMENTS,
    exact: false,
    path: "/assignments",
    component: AssignmentsPage,
    user : NCMS_ROLES.CONTENT_ADMIN
  },
  {
    featureConfigKey: featureConfigConstants.DISABLE_LIBRARY,
    exact: false,
    path: "/library",
    component: LibraryPage,
    user : NCMS_ROLES.CONTENT_ADMIN
  },
  {
    featureConfigKey: featureConfigConstants.DISABLE_MONTHLY_SCHEDULE,
    exact: false,
    path: "/monthly-schedule",
    component: MonthlySchedulePage,
    user : NCMS_ROLES.CONTENT_ADMIN
  },
  {
    exact: true,
    path: "/400",
    component: Error404,
    user : NCMS_ROLES.ALL
  },
  {
    exact: true,
    path: "/500",
    component: Error500,
    user : NCMS_ROLES.ALL
  },
  {
    exact: true,
    path: "/mainten",
    component: ErrorMainten,
    user : NCMS_ROLES.ALL
  },
  {
    featureConfigKey: featureConfigConstants.DISABLE_TERM_EXAMS,
    exact: false,
    path: "/term-exams",
    component: TermExamsPage,
    user : NCMS_ROLES.CONTENT_ADMIN
  },
  {
    featureConfigKey: featureConfigConstants.DISABLE_ANNOUNCEMENTS,
    exact: false,
    path: "/announcements",
    component: AnnouncementsPage,
    user : NCMS_ROLES.CONTENT_ADMIN
  },
  {
    featureConfigKey: featureConfigConstants.DISABLE_LEARN,
    exact: false,
    path: "/learn",
    component: LearnPage,
    user : NCMS_ROLES.CONTENT_ADMIN
  },
  {
    featureConfigKey: featureConfigConstants.DISABLE_BUILD_TEST,
    exact: false,
    path: "/build-test",
    component: BuildTestPage,
    user : NCMS_ROLES.CONTENT_ADMIN
  },
  {
    featureConfigKey: featureConfigConstants.DISABLE_WARM_UP_DELIVERY,
    exact: false,
    path: "/warm-up-delivery",
    component: WarmUpTestPage,
    user : NCMS_ROLES.CONTENT_ADMIN
  },
  {
    featureConfigKey: featureConfigConstants.DISABLE_MISCELLANEOUS,
    exact: false,
    path: "/miscellaneous",
    component: MiscellaneousPage,
    user : NCMS_ROLES.CONTENT_ADMIN
  },
  {
    featureConfigKey: featureConfigConstants.DISABLE_ACTIVITIES,
    exact: false,
    path: "/activities",
    component: ActivitiesPage,
    user : NCMS_ROLES.CONTENT_ADMIN
  },
  {
    exact: false,
    path: "/change-password",
    component: ChangePaswordPage,
    user : NCMS_ROLES.ALL
  },
  {
    featureConfigKey: featureConfigConstants.DISABLE_HIERARCHIES,
    exact: false,
    path: "/hierarchies",
    component: HierarchiesPage,
    user : NCMS_ROLES.CONTENT_ADMIN
  },
  {
    featureConfigKey: featureConfigConstants.DISABLE_GAMES,
    exact: false,
    path: "/games",
    component: GamesPage,
    user : NCMS_ROLES.CONTENT_ADMIN
  },
  {
    featureConfigKey: featureConfigConstants.DISABLE_CONFIGUE,
    exact: false,
    path: "/configue",
    component: ConfiguePage,
    user : NCMS_ROLES.PLATFORM_ADMIN
  }
];
